import React, { useEffect, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MulticomercioTableRow from "./MulticomercioTableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useTheme } from "@mui/material/styles";

export default function MulticomercioTable(props) {
  const theme = useTheme();
  const { listado, filterText } = props;
  const [filteredListado, setFilteredListado] = useState([]);
  const [paginationControl, setPaginationControl] = useState({
    rowsPerPage: 10,
    count: 1,
    page: 0,
  });

  const [openRows, setOpenRows] = useState({});
  const [sorting, setSorting] = useState({
    atributo: "",
    order: "asc",
  });

  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };

  const handleToggleRow = (id) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleRowsPerPageChange = (e) => {
    setPaginationControl({
      ...paginationControl,
      page: 0,
      rowsPerPage: e.target.value,
    });
  };

  useEffect(() => {
    const filteredList = listado.filter((row) => {
      const nombreComercio = row.nombreComercio || "";
      return nombreComercio.toLowerCase().includes(filterText.toLowerCase());
    });

    const sortedList = [...filteredList].sort((a, b) => {
      if (a.idCommercioPadre < b.idCommercioPadre)
        return sorting.order === "asc" ? -1 : 1;
      if (a.idCommercioPadre > b.idCommercioPadre)
        return sorting.order === "asc" ? 1 : -1;
      return 0;
    });

    setFilteredListado(sortedList);
    setPaginationControl((prev) => ({ ...prev, count: sortedList.length }));
  }, [listado, filterText, sorting.order, sorting.atributo]);

  const handleSort = (atributo) => {
    const isAsc = sorting.atributo === atributo && sorting.order === "asc";
    const order = isAsc ? "desc" : "asc";
    setSorting({ atributo, order });
  };

  const inicioCorte = () =>
    paginationControl.page * paginationControl.rowsPerPage;
  const corteFinal = () =>
    paginationControl.rowsPerPage * paginationControl.page +
    paginationControl.rowsPerPage;

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            width: "auto",
            overflowX: "auto",
          },
        }}
        aria-label="Tabla multicomercios"
        size="medium"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "1px", whiteSpace: "nowrap" }}></TableCell>

            <TableCell>
              <TableSortLabel
                active={sorting.atributo === "razonSocial"}
                direction={sorting.order}
                onClick={() => handleSort("razonSocial")}
              >
                <Typography>
                  <b>RAZON SOCIAL</b>
                </Typography>
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <Typography>
                <b>RUT COMERCIO</b>
              </Typography>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sorting.atributo === "numeroComercio"}
                direction={sorting.order}
                onClick={() => handleSort("numeroComercio")}
              >
                <Typography>
                  <b>N° COMERCIO</b>
                </Typography>
              </TableSortLabel>
            </TableCell>

            <TableCell sx={{ width: "1px" }}>
              <Typography>
                <b>ACCION</b>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredListado.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} style={{ textAlign: "center" }}>
                No se encontraron multi-comercios
              </TableCell>
            </TableRow>
          ) : (
            filteredListado
              .slice(inicioCorte(), corteFinal())
              .map((row) => (
                <MulticomercioTableRow
                  key={`${row.idCommercioPadre}`}
                  row={row}
                  onEdit={props.onEdit}
                  onDelete={props.onDelete}
                  open={openRows[row.idCommercioPadre]}
                  onOpen={() => handleToggleRow(row.idCommercioPadre)}
                />
              ))
          )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredListado.length}
        rowsPerPage={paginationControl.rowsPerPage}
        page={paginationControl.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </TableContainer>
  );
}
