const styles = {
    "& .MuiInputBase-root": {
      height: "32px",
      padding: "0px",
    },
    "& .MuiInputBase-input": {
      height: "32px",
    },
    "& .MuiInputBase-input:focus": {
      border: "0px !important",
    },
    "& input:focus": {
      border: "0px !important",
    },
    "& button": {
      transform: "translate(-10px, 0)",
    },
  };
  export default styles;