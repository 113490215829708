import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import RoundButton from "../../components/RoundButton";
import {
  obtenerTodosLosPadresRequest,
  eliminarCommerceDataMcRequest,
} from "../../connection/mantenedores/multicomercioMantenedor";
import { useLoader } from "../../context/LoaderContext";
import { getToken } from "../../connection/tokenController";
import { isProduction } from "../../utils/utilidades";
import MulticomercioTable from "../../components/MulticomercioTable";
import FiltroTexto from "../../components/FiltroTexto";

export const MULTICOMERCIO_MANTENEDOR_INPAGE = "multicomercio_man_in_page";

const MultiComerciosMantenedor = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [listado, setListado] = useState([]);
  const [textoFiltrar, setTextoFiltrar] = useState("");

  const handleEdit = (row) => {
    localStorage.setItem("nombreComercio", row.nombreComercio);
    navigate(`/multi-comercios/editar-multi-comercios/${row.idComercio}`, {
      state: { multiComercio: row },
    });
  };

  const handleEliminar = async (idCommerceDataMc) => {
    await eliminarCommerceDataMcRequest(idCommerceDataMc, getToken())
      .then(() => {
        showNotification("success", "Comercio eliminado correctamente");
        obtenerDatosMulticomercio(); 
      })
      .catch((error) => {
        console.error("Error al eliminar comercio:", error.message);
        showNotification("error", "Error al eliminar comercio");
      });
  };

  const handleChangeInputFilter = (texto) => {
    setTextoFiltrar(texto.toLowerCase());
  };

  const obtenerDatosMulticomercio = async () => {
    try {
      showLoader();
      const token = getToken();
      const respuesta = await obtenerTodosLosPadresRequest(token);
      if (!respuesta || !Array.isArray(respuesta)) {
        throw new Error("Datos inválidos del endpoint");
      }
      setListado(respuesta);
    } catch (error) {
      console.error("Error al obtener datos del multicomercio:", error.message);
      showNotification("error", "Error al cargar datos del comercio");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    obtenerDatosMulticomercio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        MULTI-COMERCIOS
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <FiltroTexto
            type="text"
            placeholder="Buscar comercio padre.."
            onFilter={handleChangeInputFilter}
            style={{ width: "200px", textDecoration: "none" }}
          />
          {!isProduction() && (
            <Link
              to="/multi-comercios/crear-multi-comercios"
              style={{ color: "black", textDecoration: "none" }}
            >
              <RoundButton variant="outlined">AGREGAR</RoundButton>
            </Link>
          )}
        </div>
        <br />
        <br />
        <MulticomercioTable
          listado={listado}
          onEdit={handleEdit}
          onDelete={handleEliminar}
          filterText={textoFiltrar}
        />
      </ShadowBox>
    </>
  );
};

export default MultiComerciosMantenedor;
