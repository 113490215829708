import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import { getToken } from "../../connection/tokenController";
import { obtenerHijosDePadreRequest } from "../../connection/mantenedores/multicomercioMantenedor";
import MulticomercioExpandedTableRow from "./MulticomercioExpandedTableRow";

export const MulticomercioExpandedTable = ({ idCommercioPadre, onDelete }) => {
  const [comerciosHijos, setComerciosHijos] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchComerciosHijos = async () => {
    const token = getToken();
    try {
      setLoading(true);
      const hijos = await obtenerHijosDePadreRequest(idCommercioPadre, token);
      setComerciosHijos(hijos);
    } catch (error) {
      console.error("Error al obtener los comercios hijos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idCommercioPadre) {
      fetchComerciosHijos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCommercioPadre]);

  const handleDelete = (idCommerceDataMc) => {
    onDelete(idCommerceDataMc);
    setComerciosHijos((prev) =>
      prev.filter((comercio) => comercio.idCommerceDataMc !== idCommerceDataMc)
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "1rem 0" }}>
      <Table
        style={{
          width: "fit-content",
          borderSpacing: "0 1rem",
          fontSize: "1rem",
        }}
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell>NOMBRE COMERCIO</TableCell>
            <TableCell>RUT COMERCIO</TableCell>
            <TableCell>N° COMERCIO</TableCell>
            <TableCell>ACCION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            Array(5)
              .fill(0)
              .map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={4}>
                    <Skeleton animation="pulse" variant="text" width="100%" />
                  </TableCell>
                </TableRow>
              ))
          ) : comerciosHijos.length > 0 ? (
            comerciosHijos.map((comercio) => (
              <MulticomercioExpandedTableRow
                key={comercio.idCommerceDataMc}
                idCommercioPadre={comercio}
                onDelete={handleDelete}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No hay comercios hijos disponibles.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter></TableFooter>
      </Table>
    </div>
  );
};

export default MulticomercioExpandedTable;
