import React, { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { formatearRut, isProduction } from "../../utils/utilidades";
import { useLoader } from "../../context/LoaderContext";
import { ComercioExpandedTable } from "./ComercioExpandedTable.jsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SendIcon from "@mui/icons-material/Send";
import { envioCredencialesRequest } from "../../connection/mantenedores/comercioMantenedor.js";
import { getToken } from "../../connection/tokenController";
import Dialogo from "../../components/Dialogo";
import AppsIcon from "@mui/icons-material/Apps";
import { useNavigate } from "react-router-dom";
import { isComercioUserRole } from "../../connection/loginController.js";

function cutText(texto, maxLength = 26) {
  if (texto.length > maxLength) {
    return `${texto.substring(0, maxLength)}...`;
  }
  return texto;
}

const estilos = {
  credencial: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: "#0763ed",
    },
  },
  // divGradient: {
  //   position: "absolute",
  //   width: '100%',
  //   height: '100%',
  //   background: 'linear-gradient(90deg, #00000000 0%, #FFFFFF 90%, #FFFFFFFF 100%)',
  //   pointerEvents: 'none',
  //   padding: 0
  // }
};

export default function ComerciosTableRow(props) {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const { row, variant, onEdit } = props;
  const [open, setOpen] = useState(false);
  const [dialogo, setDialogo] = useState({
    open: false,
  });

  const handleCopyClipboard = (e) => {
    const passwordBlob = new Blob([e.target.textContent], {
      type: "text/plain",
    });
    const item = new ClipboardItem({
      "text/plain": passwordBlob,
    });
    navigator.clipboard
      .write([item])
      .then(() => { })
      .catch((error) => {
        console.error(error);
      });
    showNotification("success", "Copiado al portapapeles");
  };
  //set
  const handleDesplegar = async () => {
    setOpen(!open);
  };
  // Si variant es true o el usuario no está habilitado, no se dibuja el usuario en la tabla.
  if (variant) {
    return (
      <TableRow>
        <TableCell colSpan={5} sx={{ textAlign: "center" }}>
          No hay datos o comercio no habilitado
        </TableCell>
      </TableRow>
    );
  }
  const handleSendEmail = () => {
    setDialogo({ open: true });
  };
  const sendEmails = async () => {
    showLoader();
    try {
      const send = await envioCredencialesRequest(row.idComercio, getToken());
      if (send) {
        showNotification("success", "¡Credenciales enviadas!");
      }
    } catch (error) {
      showNotification("error", "Algo salió mal");
    } finally {
      hideLoader();
    }
  };
  const handleCloseDialog = () => {
    setDialogo({ ...dialogo, open: false });
  };
  const handleAplicacion = () => {
    return navigate(`/comercios/aplicaciones/${row.idComercio}`);
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ padding: '0 0 0 0.5rem', whiteSpace: 'nowrap', width: '1px' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleDesplegar}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: '1px', textAlign: "center", whiteSpace: 'nowrap' }}>
          <Typography>{row.numeroComercioGetnet}</Typography>
        </TableCell>
        <TableCell sx={{ width: '1px', whiteSpace: 'nowrap' }}>
          <Tooltip title={row.nombreComercio.toUpperCase()}>
            <Typography>{cutText(row.nombreComercio.toUpperCase(), 20)}</Typography>
          </Tooltip>
          <Typography sx={{ fontStyle: "italic", color: "#444" }}>
            {formatearRut(row.rut)}
          </Typography>
        </TableCell>
        <TableCell sx={{ padding: "0px 0.5rem", width: '1px', whiteSpace: "nowrap" }}>
          <Typography>CLIENT ID</Typography>
          <Typography>CLIENT SECRET</Typography>
        </TableCell>
        <TableCell sx={{ padding: "0px",width: '1px', whiteSpace: "nowrap"}}>
            <Tooltip title="Copiar al portapapeles">
              <Typography onClick={handleCopyClipboard} sx={estilos.credencial}>
                {row.clientId}
              </Typography>
            </Tooltip>
            <Tooltip title="Copiar al portapapeles">
              <Typography onClick={handleCopyClipboard} sx={estilos.credencial}>
                {row.clientSecret}
              </Typography>
            </Tooltip>
        </TableCell>
        <TableCell sx={{ padding: "0px 0.5rem", whiteSpace: "nowrap", width: '1px' }}>
          <div style={{ display: 'flex' }}>
            {isProduction() || isComercioUserRole() ? (
              <></>
            ) : (
              <Tooltip title="Editar">
                <IconButton
                  aria-label="Editar"
                  onClick={() => {
                    onEdit(row);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Enviar correo">
              <IconButton onClick={handleSendEmail}>
                <SendIcon />
              </IconButton>
            </Tooltip>
            {
              isComercioUserRole() ? <></> :
                <Tooltip title="Aplicaciones">
                  <IconButton onClick={handleAplicacion} sx={{ display: "block" }}>
                    <AppsIcon></AppsIcon>
                  </IconButton>
                </Tooltip>
            }
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          timeout={1000}
        >
          <Collapse in={open} unmountOnExit>
            <Box>
              <ComercioExpandedTable row={row} open={open} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialogo
        open={dialogo.open}
        onClose={handleCloseDialog}
        title="Envio de Credenciales"
        text={"¿Estás seguro que deseas enviar este Correo Electrónico?"}
        onConfirm={sendEmails}
      />
    </>
  );
}