import React from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  Collapse,
  Box,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import MulticomercioExpandedTable from "./MulticomercioExpandedTable";

export default function MulticomercioTableRow({
  row,
  onEdit,
  open,
  onDelete,
  onOpen,
}) {
  const { idComercio, nombreComercio, numeroComercioGetnet, rut } = row;


  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={onOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{nombreComercio.toUpperCase()}</TableCell>
        <TableCell>{rut}</TableCell>
        <TableCell>{numeroComercioGetnet}</TableCell>
        
        <TableCell
          sx={{ padding: "0px 0.5rem", whiteSpace: "nowrap", width: "1px" }}
        >
          <Tooltip title="Añadir Comercio Hijo">
            <IconButton
              style={{ marginLeft: "10px" }}
              aria-label="Añadir MC"
              onClick={() => onEdit(row)}
            >
              <AddBusinessIcon />
            </IconButton>
          </Tooltip>
          </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={5} style={{ padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <MulticomercioExpandedTable
                open={true}
                idCommercioPadre={idComercio}
                onDelete={onDelete}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}