import React, { useEffect, useState } from 'react'

const estilos = {
  container: {
    width: '120px',
    border: '1px solid #00000022',
    padding: '8px 0',
    borderRadius: '4px',
    boxShadow: '2px 2px 8px #00000022'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #00000088',
    margin: '0px 8px',
    paddingBottom: '4px'
  },
  cuerpo: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px 8px 0 8px'
  },
  circulo: {
    border: '1px solid #00000088',
    width: '10px',
    height: '10px',
    borderRadius: '50px'
  },
  circuloSeleccionado: {
    border: '1px solid #00000088',
    width: '10px',
    height: '10px',
    borderRadius: '50px',
    backgroundColor: "#00FF00"
  }
}

export default function TinyCalendario({ calendario }) {
  const [seleccionados, setSeleccionados] = useState({
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false
  })
  useEffect(() => {
    const texto = calendario.toLowerCase();
    if (texto.includes('lunes'))
      setSeleccionados(prev => ({...prev, lunes: true}))
    if (texto.includes('martes'))
      setSeleccionados(prev => ({...prev, martes: true}))
    if (texto.includes('miercoles') || texto.includes('miércoles'))
      setSeleccionados(prev => ({...prev, miercoles: true}))
    if (texto.includes('jueves'))
      setSeleccionados(prev => ({...prev, jueves: true}))
    if (texto.includes('viernes'))
      setSeleccionados(prev => ({...prev, viernes: true}))
    if (texto.includes('sabado') || texto.includes('sábado'))
      setSeleccionados(prev => ({...prev, sabado: true}))
    if (texto.includes('domingo'))
      setSeleccionados(prev => ({...prev, domingo: true}))
  }, [calendario])
  return (
    <div style={estilos.container}>
      <div style={estilos.title}>
        <div>L</div>
        <div>M</div>
        <div>M</div>
        <div>J</div>
        <div>V</div>
        <div>S</div>
        <div>D</div>
      </div>
      <div style={estilos.cuerpo}>
        <div style={seleccionados.lunes ? estilos.circuloSeleccionado : estilos.circulo}></div>
        <div style={seleccionados.martes ? estilos.circuloSeleccionado : estilos.circulo}></div>
        <div style={seleccionados.miercoles ? estilos.circuloSeleccionado : estilos.circulo}></div>
        <div style={seleccionados.jueves ? estilos.circuloSeleccionado : estilos.circulo}></div>
        <div style={seleccionados.viernes ? estilos.circuloSeleccionado : estilos.circulo}></div>
        <div style={seleccionados.sabado ? estilos.circuloSeleccionado : estilos.circulo}></div>
        <div style={seleccionados.domingo ? estilos.circuloSeleccionado : estilos.circulo}></div>
      </div>
    </div>
  )
}
