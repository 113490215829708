import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import PromocionesTableRow from "./PromocionesTableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

const styles = {
  smallCell: {
    width: "1%",
    whiteSpace: "nowrap",
  },
};

export default function PromocionesTable({
  listado,
  onEdit,
  filterText,
  idComercio,
}) {
  const [paginationControl, setPaginationControl] = useState({
    page: 0,
    rowsPerPage: 10,
  });
  const [filteredListado, setFilteredListado] = useState([]);
  const [sorting, setSorting] = useState({
    atributo: "",
    order: "asc",
  });
  const [openRows, setOpenRows] = useState({});

  const toggleApp = async (item) => {
    item.habilitado = !item.habilitado;
  };

  useEffect(() => {
    const array = listado.filter((promocion) => {
      const idPromo = promocion.idPromo || "";
      return idPromo.toString().includes(filterText.toLowerCase());
    });
    const arrayOrdenado = [...array].sort((a, b) => b.idPromocion - a.idPromocion);

    setFilteredListado(arrayOrdenado);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, listado]);

  const handleSort = (atributo) => {
    const order =
      sorting.atributo === atributo && sorting.order === "asc" ? "desc" : "asc";
    setSorting({ atributo, order });
    const sortedList = [...filteredListado].sort((a, b) => {
      if (atributo === "vigenciaDesde" || atributo === "vigenciaHasta") {
        const dateA = new Date(a[atributo]);
        const dateB = new Date(b[atributo]);
        return order === "asc" ? dateA - dateB : dateB - dateA;
      }
      if (a[atributo] < b[atributo]) return order === "asc" ? -1 : 1;
      if (a[atributo] > b[atributo]) return order === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredListado(sortedList);
  };

  const handleToggleRow = (id) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleRowsPerPageChange = (e) => {
    setPaginationControl({
      ...paginationControl,
      page: 0,
      rowsPerPage: parseInt(e.target.value, 10),
    });
  };

  const handleChangePage = (e, newPage) => {
    setPaginationControl({ ...paginationControl, page: newPage });
  };

  return (
    <TableContainer>
      <Table aria-label="promociones table">
        <TableHead>
          <TableRow>
            <TableCell sx={styles.smallCell}></TableCell>
            <TableCell>
              <Typography>
                <TableSortLabel
                  active={sorting.atributo === "idPromo"}
                  direction={sorting.order}
                  onClick={() => handleSort("idPromo")}
                >
                  ID PROMO
                </TableSortLabel>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <TableSortLabel
                  active={sorting.atributo === "vigenciaDesde"}
                  direction={sorting.order}
                  onClick={() => handleSort("vigenciaDesde")}
                >
                  VIGENCIA DESDE
                </TableSortLabel>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <TableSortLabel
                  active={sorting.atributo === "vigenciaHasta"}
                  direction={sorting.order}
                  onClick={() => handleSort("vigenciaHasta")}
                >
                  VIGENCIA HASTA
                </TableSortLabel>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <TableSortLabel
                  active={sorting.atributo === "porcentajeDescuento"}
                  direction={sorting.order}
                  onClick={() => handleSort("porcentajeDescuento")}
                >
                  DESCUENTO
                </TableSortLabel>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <TableSortLabel
                  active={sorting.atributo === "topes"}
                  direction={sorting.order}
                  onClick={() => handleSort("topes")}
                >
                  TOPES
                </TableSortLabel>
              </Typography>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sorting.atributo === "cantidad"}
                direction={sorting.order}
                onClick={() => handleSort("cantidad")}
              >
                CANTIDAD
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sorting.atributo === "calendario"}
                direction={sorting.order}
                onClick={() => handleSort("calendario")}
              >
                CALENDARIO
              </TableSortLabel>
            </TableCell>
           
            <TableCell sx={styles.smallCell}>
              <Typography>ACCIÓN</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredListado.length === 0 ? (
            <TableRow>
              <TableCell colSpan={9} align="center">
                <Typography variant="body1" color="textSecondary">
                  No se encontraron promociones.
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            filteredListado
              .slice(
                paginationControl.rowsPerPage * paginationControl.page,
                paginationControl.rowsPerPage * (paginationControl.page + 1)
              )
              .map((promocion) => (
                <React.Fragment key={promocion.idPromocion}>
                  <PromocionesTableRow
                    item={promocion}
                    onEdit={onEdit}
                    toggleApp={toggleApp}
                    idComercio={idComercio}
                    open={openRows[promocion.idPromocion]}
                    onOpen={() => handleToggleRow(promocion.idPromocion)}
                  />
                </React.Fragment>
              ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        labelRowsPerPage="Filas por página:"
        count={filteredListado.length}
        rowsPerPage={paginationControl.rowsPerPage}
        page={paginationControl.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </TableContainer>
  );
}
