import React, { useEffect, useState } from "react";
import { Grid, Typography, Switch, FormControlLabel } from "@mui/material";
import ShadowBox from "../ShadowBox";
import Button from "../Button";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../Input/Input";
import { useLoader } from "../../context/LoaderContext";
import CustomBreadcrumbs from "../CustomBreadcrumbs";
// import {
//   crearSucursalMcRequest,
//   editarSucursalMcRequest,
// } from "../../connection/mantenedores/multicomercioMantenedor";
import { getToken } from "../../connection/tokenController";

const breadcrumbItems = [
  { href: "/multi-comercios", label: "multi-comercios" },
];

export default function EditarCrearSucursales() {
  const navigate = useNavigate();
  const location = useLocation();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [isEdit, setIsEdit] = useState(false);
  const [inputsError, setInputsError] = useState("");
  const [sucursal, setSucursal] = useState({
    idCommerceDataMc: "",
    numeroSucursal: "",
    nombreCorto: "",
    NombreSucursal: "",
    comunaSucursal: "",
    direccionSucursal: "",
    habilitado: true,
  });

  const handleVolver = (e) => {
    e.preventDefault();
    navigate("/multi-comercios");
  };

  const handleChange = (field, value) => {
    setSucursal({ ...sucursal, [field]: value });
  };

  useEffect(() => {
    if (location.state?.sucursal) {
      setSucursal(location.state.sucursal);
      setIsEdit(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state?.idCommerceDataMc) {
      setSucursal((prevState) => ({
        ...prevState,
        idCommerceDataMc: location.state.idCommerceDataMc, 
      }));
    }
  }, [location.state]); 

  const validarDatos = () => {
    if (!sucursal.direccionSucursal.trim() || !sucursal.numeroSucursal.trim()) {
      setInputsError("Por favor, complete los campos obligatorios.");
      return false;
    }
    return true;
  };

  // const handleCrear = async (e) => {
  //   e.preventDefault();
  //   if (!validarDatos()) return;
  //   setInputsError("");

  //   try {
  //     showLoader();
  //     const token = getToken();
  //     await crearSucursalMcRequest({ ...sucursal }, token);
  //     showNotification("success", "Sucursal creada con éxito");
  //     navigate("/multi-comercios");
  //   } catch (error) {
  //     console.error("Error al crear la sucursal:", error);
  //     showNotification("error", "Error al crear la sucursal");
  //   } finally {
  //     hideLoader();
  //   }
  // };

  // const handleEditar = async (e) => {
  //   e.preventDefault();
  //   if (!validarDatos()) return;
  //   setInputsError("");

  //   try {
  //     showLoader();
  //     const token = getToken();
  //     await editarSucursalMcRequest(
  //       sucursal.idSucursal,
  //       { ...sucursal },
  //       token
  //     );
  //     showNotification("success", "Sucursal editada con éxito");
  //     navigate("/multi-comercios");
  //   } catch (error) {
  //     console.error(error);
  //     showNotification("error", "Error al editar la sucursal");
  //   } finally {
  //     hideLoader();
  //   }
  // };

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        {isEdit ? "EDITAR" : "CREAR"} SUCURSAL
      </Typography>
      <ShadowBox style={{ padding: "2rem" }}>
        <CustomBreadcrumbs
          items={[...breadcrumbItems, { label: isEdit ? "EDITAR" : "CREAR" }]}
        />
        <br />
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <label style={{ color: "black" }}>NOMBRE SUCURSAL</label>
              <Input
                type="text"
                placeholder="Nombre Sucursal"
                value={sucursal.NombreSucursal}
                onChange={(e) => handleChange("NombreSucursal", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label style={{ color: "black" }}>NOMBRE CORTO</label>
              <Input
                type="text"
                placeholder="Nombre Corto"
                value={sucursal.nombreCorto}
                onChange={(e) => handleChange("nombreCorto", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <label style={{ color: "black" }}> NÚMERO DE SUCURSAL</label>
              <Input
                type="text"
                placeholder="Número de la Sucursal"
                value={sucursal.numeroSucursal}
                onChange={(e) => handleChange("numeroSucursal", e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <label style={{ color: "black" }}>COMUNA</label>
              <Input
                type="text"
                placeholder="Comuna"
                value={sucursal.comunaSucursal}
                onChange={(e) => handleChange("comunaSucursal", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label style={{ color: "black" }}> DIRECCIÓN</label>
              <Input
                type="text"
                placeholder="Dirección"
                value={sucursal.direccionSucursal}
                onChange={(e) =>
                  handleChange("direccionSucursal", e.target.value)
                }
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <label style={{ color: "black" }}>HABILITADO</label>
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={sucursal.habilitado}
                    onChange={(e) =>
                      handleChange("habilitado", e.target.checked)
                    }
                    color="primary"
                  />
                }
              />
            </Grid>

            {inputsError && (
              <Grid item xs={12}>
                <div style={{ marginTop: "10px" }}>{inputsError}</div>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                style={{ marginRight: "10px" }}
                variant="gray"
                onClick={handleVolver}
              >
                CANCELAR
              </Button>
              {isEdit ? (
                <Button onClick={console.log("guardar")}>GUARDAR</Button>
              ) : (
                <Button onClick={console.log("guardar")}>ACEPTAR</Button>
              )}
            </Grid>
          </Grid>
        </form>
      </ShadowBox>
    </>
  );
}
