import { TableCell, TableRow } from '@mui/material'
import React from 'react'

export default function BinesExpandedTableRow({row}) {
    const marcaTarget = {
        "VI": "Visa",
        "MC": "MasterCard",
        "AX": "Amex"
    }
    const tipoTarget = {
        "D": "Débito",
        "C": "Crédito",
        "P": "Prepago"
    }
    const pais = {
        "CHL": "Chile"
    }

    return (
        <TableRow>
            <TableCell>{marcaTarget[row.marca]}</TableCell>
            <TableCell>{tipoTarget[row.tipo]}</TableCell>
            <TableCell>{row.rangoDesde}</TableCell>
            <TableCell>{row.rangoHasta}</TableCell>
            <TableCell>{row.codigoPais}</TableCell>
            <TableCell>{pais[row.pais]}</TableCell>
        </TableRow>
    )
}
