import { getApiUrl, RequestContentType, getRequest, postRequest, putRequest, deleteRequest } from "../apiConnection";

const API_URL = getApiUrl();

export const crearCommerceDataMcRequest = async (objeto, token) => {
  const url = "/api/commercedatamc";
  return await postRequest(API_URL, url, RequestContentType.APPLICATION_JSON, objeto, token);
};

export const listarCommerceDataMcRequest = async (idCommerceDataMc, token) => {
  const url = `/api/commercedatamc/${idCommerceDataMc}`;
  return await getRequest(API_URL, url, token);
};

export const listarCommerceDataMcPorIdComercioRequest = async (idComercio, token) => {
  const url = `/api/commercedatamc?idComercio=${idComercio}`;
  return await getRequest(API_URL, url, token);
};

export const obtenerTodosLosPadresRequest = async (token) => {
  const url = "/api/comercios?mc=true";
  return await getRequest(API_URL, url, token);
};

export const obtenerHijosDePadreRequest = async (idComercioPadre, token) => {
  const url = `/api/comercios?idComercioPadre=${idComercioPadre}&mc=true`;
  return await getRequest(API_URL, url, token);
};

export const editarCommerceDataMcRequest = async (idCommerceDataMc, objeto, token) => {
  const url = `/api/commercedatamc/${idCommerceDataMc}`;
  return await putRequest(API_URL, url, RequestContentType.APPLICATION_JSON, objeto, token);
};

export const eliminarCommerceDataMcRequest = async (idCommerceDataMc, token) => {
  const url = `/api/commercedatamc/${idCommerceDataMc}`;
  return await deleteRequest(API_URL, url, token);
};

// // SUCURSALES MC

// export const crearSucursalMcRequest = async (objeto, token) => {
//   const url = "/api/sucursalesmc";
//   return await postRequest(API_URL, url, RequestContentType.APPLICATION_JSON, objeto, token);
// };

// export const listarSucursalMcRequest = async (idSucursalMc, token) => {
//   const url = `/api/sucursalesmc/${idSucursalMc}`;
//   return await getRequest(API_URL, url, token);
// };

// export const listarSucursalesMcPorIdCommerceDataRequest = async (idCommerceDataMc, token) => {
//   const url = `/api/sucursalesmc?idCommerceDataMc=${idCommerceDataMc}`;
//   return await getRequest(API_URL, url, token);
// };

// export const editarSucursalMcRequest = async (idSucursalMc, objeto, token) => {
//   const url = `/api/sucursalesmc/${idSucursalMc}`;
//   return await putRequest(API_URL, url, RequestContentType.APPLICATION_JSON, objeto, token);
// };

// export const eliminarSucursalMcRequest = async (idSucursalMc, token) => {
//   const url = `/api/sucursalesmc/${idSucursalMc}`;
//   return await deleteRequest(API_URL, url, token);
// };
