import React, { useEffect } from 'react'
import Transacciones from '../../components/Transacciones'
import { obtenerTxsMCRequest } from '../../connection/mantenedores/transacciones';
import { getToken } from '../../connection/tokenController';
import { useLoader } from '../../context/LoaderContext';
import { isLoginComercio } from '../../connection/loginController';
import { APP_MULTICOMERCIO, obtenerAppsHabilitadas } from '../../connection/AppsController';
import { useNavigate } from 'react-router-dom';

export default function MultiComerciosTransacciones() {
    const { showLoader, hideLoader, showNotification } = useLoader();
    const navigate = useNavigate()
    
    const handleBuscar = async (idPos = '', fechaDesde = '', fechaHasta = '', comandos = '') => {
        return new Promise(async (resolve) => {
            try {
                showLoader();
                const respuesta = await obtenerTxsMCRequest(
                    idPos,
                    fechaDesde,
                    fechaHasta,
                    comandos,
                    getToken()
                );
                const listado = respuesta.map((item) => ({
                    idTxs: item.idMcTxs,
                    comando: item.comando,
                    fechaRequest: new Date(item.fechaRequest),
                    functionCode: item.functionCode,
                    responseCode: item.responseCode,
                    responseMessage: item.responseMessage,
                    fechaResponse: new Date(item.fechaResponse),
                    request: item.request,
                    response: item.response,
                }));
                resolve(listado);
            } catch (error) {
                console.error(error); 
                resolve([]);
                showNotification(
                    "error",
                    error.status === 500
                        ? "Error de conexión"
                        : "Ha ocurrido un error inesperado"
                );
            } finally {
                hideLoader();
            }
        });
    };
    
    useEffect(() =>{
        checkearValidRoute()
        // eslint-disable-next-line
    },[])

    
    const checkearValidRoute = async () =>  {
        if (!isLoginComercio()) return;
        const nombresApps = await obtenerAppsHabilitadas();
        if (!nombresApps.includes(APP_MULTICOMERCIO)) {
          navigate('/404');
        }
      }
    return (
        <Transacciones titulo="MULTICOMERCIO" onBuscar={handleBuscar} variant="multicomercio" />
    )
}
