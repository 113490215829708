import { getIdComercio } from "./loginController";
import { obtenerAppComercio, obtenerListadoApps } from "./mantenedores/aplicacionesMantenedor";
import { getToken } from "./tokenController";

export const APP_DONACIONES = "g-donaciones";
export const APP_C2C = "g-services";
export const APP_GSCAN = "g-scan";
export const APP_MULTICOMERCIO = "multi-comercios"


export async function obtenerAppsHabilitadas() {
    const [appComercio, appHabilitadas] = await Promise.all([
    obtenerAppComercio(getIdComercio(), getToken()),
    obtenerListadoApps(getToken())
  ]);

  const nombresApps = appComercio
    .map(({ idApps }) => appHabilitadas.find(app => app.idApps === idApps)?.nombre.toLowerCase())
    .filter(Boolean);

  return nombresApps;
}
