import React from "react";
import Papa from "papaparse";
import { useLoader } from "../../context/LoaderContext";

export default function CSVToObject({ onChange }) {
  const { showNotification } = useLoader();

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (!file) {
      showNotification("error", "Por favor, selecciona un archivo CSV.");
      return;
    }

    if (file.type !== "text/csv") {
      showNotification("error", "El archivo debe ser en formato CSV.");
      return;
    }

    Papa.parse(file, {
      complete: (result) => {
        const transformedData = transformCSVToBines(result.data);

        showNotification("success", "Archivo procesado con éxito.");
        onChange(transformedData);
      },
      error: () => {
        showNotification(
          "error",
          "Hubo un error al procesar el archivo. Intenta con otro archivo."
        );
      },
      header: true,
      skipEmptyLines: true,
    });
  };

  const transformCSVToBines = (csvData) => {
    return csvData.map((row) => ({
      rangoDesde: row.rangoDesde,
      rangoHasta: row.rangoHasta,
      marca: row.marca,
      tipo: row.tipo,
      pais: row.pais,
      codigoPais: row.codigoPais,
    }));
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
    </div>
  );
}
