import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import Button from "../../components/Button";
import Combobox from "../../components/Combobox";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import { useLoader } from "../../context/LoaderContext";
import { isProduction } from "../../utils/utilidades";
import { getToken } from "../../connection/tokenController";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import { obtenerTodosLosComerciosRequest } from "../../connection/mantenedores/comercioMantenedor";
import { crearCommerceDataMcRequest } from "../../connection/mantenedores/multicomercioMantenedor";

const breadcrumbItems = [
  { href: "/multi-comercios", label: "Multi Comercios" },
];

export default function EditarCrearMultiComercios() {
  const navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [isEdit, setIsEdit] = useState(false);
  const [inputsError, setInputsError] = useState("");
  const [multiComercio, setMultiComercio] = useState({
    numeroComercio: "",
    razonSocial: "",
    rutComercio: "",
    habilitado: true,
  });
  const [comercios, setComercios] = useState([]);
  const [comercioPadre, setComercioPadre] = useState(null);
  const [comercioHijo, setComercioHijo] = useState(null);
  const [binPermitido, setBinPermitido] = useState(null);
  const [binRestringido, setBinRestringido] = useState(null);
  let location = useLocation();
  const inputFilePermitido = useRef(null);
  const inputFileRestringido = useRef(null);

  const handleVolver = (e) => {
    e.preventDefault();
    navigate("/multi-comercios");
  };

  const handleFileUpload = (event, setFile) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile({
          name: file.name,
          content: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSetComercios = async () => {
    try {
      showLoader();
      const token = getToken();
      const listadoComercios = await obtenerTodosLosComerciosRequest(token);
      setComercios(
        listadoComercios
          .filter((x) => x.habilitado)
          .map((x) => ({
            nombre: x.nombreComercio.toUpperCase(),
            codigo: x.idComercio,
            disabled: false,
          }))
      );
    } catch (error) {
      showNotification("error", "Error al cargar comercios");
    } finally {
      hideLoader();
    }
  };

  const updateComerciosState = (selectedPadre, selectedHijo) => {
    setComercios((prev) =>
      prev.map((comercio) => ({
        ...comercio,
        disabled:
          comercio.codigo === selectedPadre?.codigo ||
          comercio.codigo === selectedHijo?.codigo,
      }))
    );
  };

  const handleComercioPadreChange = (row) => {
    setComercioPadre(row);
    if (comercioHijo?.codigo === row.codigo) {
      setComercioHijo(null);
    }
    updateComerciosState(row, comercioHijo);
  };

  const handleComercioHijoChange = (row) => {
    setComercioHijo(row);
    if (comercioPadre?.codigo === row.codigo) {
      setComercioPadre(null);
    }
    updateComerciosState(comercioPadre, row);
  };

  useEffect(() => {
    localStorage.setItem("MULTICOMERCIO_MANTENEDOR_INPAGE", "true");

    if (location.state?.comercioPadre) {
      setComercioPadre({
        nombre: location.state.comercioPadre.nombreComercio,
        codigo: location.state.comercioPadre.idComercio,
      });
    }

    if (location.state?.multiComercio) {
      setMultiComercio(location.state.multiComercio);
      setIsEdit(true);

      if (location.state.multiComercio.comercioHijo) {
        setComercioHijo({
          nombre: location.state.multiComercio.comercioHijo.nombreComercio,
          codigo: location.state.multiComercio.comercioHijo.idComercio,
        });
      }
    }

    // Recuperar el nombre del comercio padre desde localStorage
    const storedNombreComercio = localStorage.getItem("nombreComercio");
    if (storedNombreComercio) {
      setComercioPadre((prev) => ({
        ...prev,
        nombre: storedNombreComercio,
      }));
    }

    handleSetComercios(); // Carga inicial de comercios
    // eslint-disable-next-line
  }, [location.state]);

  useEffect(() => {
    if (isProduction()) navigate("/multi-comercios");
    handleSetComercios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validarDatos = () => {
    if (
      !multiComercio.numeroComercio.trim() ||
      !multiComercio.razonSocial.trim() ||
      !multiComercio.rutComercio.trim() ||
      !comercioPadre ||
      !comercioHijo ||
      comercioPadre.codigo === comercioHijo.codigo
    ) {
      setInputsError(
        "Por favor, complete los campos obligatorios y asegúrese de que el comercio padre no sea igual al hijo."
      );
      return false;
    }
    return true;
  };

  const handleCrear = async (e) => {
    e.preventDefault();
    if (!validarDatos()) return;
    setInputsError("");

    const token = getToken();
    const objeto = {
      ...multiComercio,
      comercioPadre: comercioPadre.codigo,
      comercioHijo: comercioHijo.codigo,
    };

    try {
      showLoader();
      await crearCommerceDataMcRequest(objeto, token);
      showNotification("success", "Multi Comercio creado con éxito");
      navigate("/multi-comercios");
    } catch (error) {
      console.error(error);
      showNotification("error", "Error al crear el comercio");
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        {isEdit ? "EDITAR" : "CREAR"}
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        <CustomBreadcrumbs
          items={[...breadcrumbItems, { label: isEdit ? "EDITAR" : "CREAR" }]}
        />
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              {isEdit && comercioPadre?.nombre ? (
                <h2 style={{ padding: "0rem 0.2rem" }}>
                  {comercioPadre.nombre}
                </h2>
              ) : (
                <>
                  <label style={{ color: "black" }}>COMERCIO PADRE</label>
                  <Combobox
                    style={{ width: "200px" }}
                    rows={comercios}
                    placeholder="Seleccione Comercio Padre"
                    selectedRow={comercioPadre}
                    onSelect={handleComercioPadreChange}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <label style={{ color: "black" }}>COMERCIO HIJO</label>
              <Combobox
                style={{ width: "200px" }}
                rows={comercios}
                placeholder="Seleccione Comercio Hijo"
                selectedRow={comercioHijo}
                onSelect={handleComercioHijoChange}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <label style={{ color: "black" }}>
                ADJUNTAR ARCHIVO DE BINES PERMITIDOS
              </label>
              <Input
                type="file"
                accept=".xls,.xlsx"
                inputRef={inputFilePermitido}
                onChange={(e) => handleFileUpload(e, setBinPermitido)}
              />
              {binPermitido && (
                <Typography variant="body2" color="textSecondary">
                  Archivo: {binPermitido.name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <label style={{ color: "black" }}>
                ADJUNTAR ARCHIVO DE BINES RESTRINGIDOS
              </label>
              <Input
                type="file"
                accept=".xls,.xlsx"
                inputRef={inputFileRestringido}
                onChange={(e) => handleFileUpload(e, setBinRestringido)}
              />
              {binRestringido && (
                <Typography variant="body2" color="textSecondary">
                  Archivo: {binRestringido.name}
                </Typography>
              )}
            </Grid>
            {inputsError && (
              <Grid item xs={12}>
                <div style={{ color: "red", marginTop: "10px" }}>
                  {inputsError}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                style={{ marginRight: "10px" }}
                variant="gray"
                onClick={handleVolver}
              >
                CANCELAR
              </Button>
              <Button onClick={handleCrear}>ACEPTAR</Button>
            </Grid>
          </Grid>
        </form>
      </ShadowBox>
    </>
  );
}
