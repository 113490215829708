import React from "react";
import "./styles.css";
import { AppBar, Container, IconButton, Toolbar, Typography } from "@mui/material";
import logo from "../../resources/images/logo.png";
import RoundButton from "../RoundButton";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

export default function Navbar(props) {
  let navigate = useNavigate();
  const handleLogout = () => {
    // Aquí puedes agregar cualquier lógica adicional para manejar el cierre de sesión,
    // como limpiar datos del usuario en el estado global o en localStorage.

    // Redireccionar a la página de logout.
    navigate("/logout");
  };
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      style={{ backgroundColor: "white" }}
      component="nav"
    >
      <Container maxWidth="x2">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Toolbar
            sx={{
              flexGrow: 1,
              display: { xs: "flex", sm: "none" },
              justifyContent: "left",
            }}
          >
            <IconButton
              size="large"
              color="dark"
              onClick={props.onHamburgerClick}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <Toolbar sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <img src={logo} width="120px" alt="logo" />
          </Toolbar>
          <Toolbar>
            <Typography variant="h5" sx={{ color: '#444' }}>ADCOM</Typography>
          </Toolbar>
          <Toolbar sx={{ display: { sx: 'flex', sm: 'none' } }}>
            <img src={logo} width="120px" alt="logo" />
          </Toolbar>
          <Toolbar
            sx={{
              display: { sm: "flex", xs: "none" },
              gap: "1rem",
              justifyContent: "right",
            }}
          >
            <RoundButton onClick={handleLogout}>CERRAR SESIÓN</RoundButton>
          </Toolbar>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
