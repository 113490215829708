import { getApiUrl, RequestContentType, deleteRequest, getRequest, postRequest, putRequest } from "../apiConnection";

const API_URL = getApiUrl();

export async function obtenerTodosLosComerciosRequest(token) {
    return await getRequest(API_URL, '/api/comercios', token);
}

// idapps api/comercios?idapps=1
export async function obtenerComerciosPorIdAppRequest(idApp, token) {
    return await getRequest(API_URL, `/api/comercios?idapps=${idApp}`, token);
}


export async function crearComercioRequest(comercio, token) {
    return await postRequest(API_URL, '/api/comercios', RequestContentType.APPLICATION_JSON, comercio, token);
}

export async function editarComercioRequest(comercio, token, id) {
    return await putRequest(API_URL, `/api/comercios/${id}`, RequestContentType.APPLICATION_JSON, comercio, token); //url, path, type, body, token
}

export async function obtenerComercioPorIdRequest(id, token){
    return await getRequest(API_URL, `/api/comercios/${id}`, token);
}

export async function obtenerComercioPorMC(id, token) {
    return await getRequest(API_URL, `/api/comercios?mc=true&id=${id}`, token);
}

//EMAILS MANTENEDORES
export async function crearEmailRequest(body, token){
    return await postRequest(API_URL, `/api/emailscomercios`, RequestContentType.APPLICATION_JSON, body, token)
    /*{
 “idComercio”: 1,
 “email”: “admin@ione.cl”,
 “habilitado”: true*/
}


export async function obtenerEmailsRequest(id, token){
    return await getRequest(API_URL, `/api/emailscomercios?idComercio=${id}`, token)
}

export async function editarEmailRequest(body, id, token){
    return await putRequest(API_URL, `/api/emailscomercios/${id}`, RequestContentType.APPLICATION_JSON, body, token)
}

export async function eliminarEmailRequest(id, token){
    return await deleteRequest(API_URL, `/api/emailscomercios/${id}`, token)
}

export async function envioCredencialesRequest(id, token){
    return await postRequest(API_URL, `/api/emailscomercios/send?idComercio=${id}`, RequestContentType.APPLICATION_JSON,null, token)
}