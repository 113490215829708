import React, { useEffect, useState } from "react";
import { Typography, Grid, useMediaQuery } from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import Button from "../../components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import Input from "../../components/Input/Input";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import { getToken } from "../../connection/tokenController";
import Combobox from "../../components/Combobox/Combobox";
import ChipList from "../../components/ChipList";
import {
  crearPromocionRequest,
  editarPromocionRequest,
} from "../../connection/mantenedores/promocionesMantenedor";
import { useLoader } from "../../context/LoaderContext";
import { obtenerTodosLosComerciosRequest } from "../../connection/mantenedores/comercioMantenedor";
import SelectorFechaHora from "../../components/SelectorFecha/SelectorFechaHora";
import CSVToObject from "../../components/CsvToObjectPromocion/CSVToObject";
import {
  SELECTED_COMMERCE_PROMO,
  PROMO_MANTENEDOR_INPAGE,
} from "./PromocionesMantenedor";

const breadcrumbItems = [{ href: "/promociones", label: "Promociones" }];

const Dias = [
  { nombre: "TODOS" },
  { nombre: "LUNES" },
  { nombre: "MARTES" },
  { nombre: "MIÉRCOLES" },
  { nombre: "JUEVES" },
  { nombre: "VIERNES" },
  { nombre: "SÁBADO" },
  { nombre: "DOMINGO" },
];

export default function EditarCrearPromocion() {
  const [vigenciaDateDesde, setVigenciaDateDesde] = useState(null);
  const [vigenciaDateHasta, setVigenciaDateHasta] = useState(null);

  const [inputsError, setInputsError] = useState("");

  const { showLoader, hideLoader, showNotification } = useLoader();
  let location = useLocation();
  let navigate = useNavigate();
  const [diasSeleccionados, setDiasSeleccionados] = useState([]);
  const [selectedDia, setSelectedDia] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [comercios, setComercios] = useState([]);
  const [selectedComercio, setSelectedComercio] = useState(false);
  const [promocion, setPromocion] = useState({
    idComercio: null,
    idPromo: "",
    porcentajeDescuento: 0,
    topes: 0,
    vigenciaDesde: "",
    vigenciaHasta: "",
    calendario: "",
    cantidad: 0,
    bines: [
      {
        rangoDesde: "",
        rangoHasta: "",
        marca: "",
        tipo: "",
        pais: "",
        codigoPais: "",
      },
    ],
    habilitado: true,
  });

  const handleSelectDia = (dia) => {
    if (dia.nombre === "TODOS") {
      if (diasSeleccionados.length !== 7) {
        setDiasSeleccionados(Dias.slice(1));
        setPromocion((prev) => ({
          ...prev,
          calendario: Dias.slice(1)
            .map((d) => d.nombre)
            .join(", "),
        }));
      }
    } else {
      if (!diasSeleccionados.some((d) => d.nombre === dia.nombre)) {
        const nuevosDias = [...diasSeleccionados, dia];
        setDiasSeleccionados(nuevosDias);
        setPromocion((prev) => ({
          ...prev,
          calendario: nuevosDias.map((d) => d.nombre).join(", "),
        }));
      }
    }

    setSelectedDia(null);
  };

  const handleDeleteDia = (dia) => {
    if (dia.nombre === "TODOS") {
      setDiasSeleccionados([]);
      setPromocion((prev) => ({
        ...prev,
        calendario: "",
      }));
    } else {
      const nuevosDias = diasSeleccionados.filter(
        (d) => d.nombre !== dia.nombre
      );
      setDiasSeleccionados(nuevosDias);
      setPromocion((prev) => ({
        ...prev,
        calendario: nuevosDias.map((d) => d.nombre).join(", "),
      }));
    }
  };

  function handleSetIdComercio() {
    try {
      const comercioStr = localStorage.getItem(SELECTED_COMMERCE_PROMO);
      const comercio = JSON.parse(comercioStr);
      if (comercio && comercio.codigo) {
        // Validación agregada
        setPromocion((prev) => ({ ...prev, idComercio: comercio.codigo }));
        setSelectedComercio(true);
      }
    } catch (error) {
      console.error("Error al obtener el comercio:", error);
    }
  }

  const handleChange = (field) => (e) => {
    let value = e.target.value;

    if (field === "habilitado") {
      value = value === "Sí";
    }
    if ((field === "porcentajeDescuento", "tope", "cantidad" && value < 0)) {
      value = 0;
    }

    setPromocion({ ...promocion, [field]: value });
  };

  const handleChangeVigenciaDateDesde = (date) => {
    if (!date || isNaN(date)) {
      console.error("Fecha inválida", date);
      return;
    }
    const fecha = new Date(date);
    fecha.setHours(fecha.getHours() - 3);

    const formattedDate = fecha.toISOString().slice(0, 19);

    setVigenciaDateDesde(date);
    setPromocion((prev) => ({
      ...prev,
      vigenciaDesde: formattedDate,
    }));
  };
  const handleChangeVigenciaDateHasta = (date) => {
    if (!date || isNaN(date)) {
      console.error("Fecha inválida", date);
      return;
    }

    const fecha = new Date(date);
    fecha.setHours(fecha.getHours() - 3);

    const formattedDate = fecha.toISOString().slice(0, 19);

    setVigenciaDateHasta(date);
    setPromocion((prev) => ({
      ...prev,
      vigenciaHasta: formattedDate,
    }));
  };

  const handleVolver = (e) => {
    e.preventDefault();
    navigate("/promociones");
  };

  useEffect(() => {
    localStorage.setItem(PROMO_MANTENEDOR_INPAGE, "true");
    if (location.state.selectedComercio) {
      setPromocion({
        ...promocion,
        idComercio: location.state.selectedComercio.codigo,
      });
      setSelectedComercio(location.state.selectedComercio);
    }

    if (location.state?.promocion) {
      setPromocion(location.state.promocion);
      setIsEdit(true);

      if (location.state.promocion.calendario) {
        const dias = location.state.promocion.calendario
          .split(", ")
          .map((nombre) => ({ nombre }));
        setDiasSeleccionados(dias);
      }

      if (location.state.promocion.vigenciaDesde) {
        const vigenciaFormatted = new Date(
          location.state.promocion.vigenciaDesde
        );
        setVigenciaDateDesde(vigenciaFormatted);
      }
      if (location.state.promocion.vigenciaHasta) {
        const vigenciaFormatted = new Date(
          location.state.promocion.vigenciaHasta
        );
        setVigenciaDateHasta(vigenciaFormatted);
      }

      handleSetComercios();
      handleSetIdComercio();
    }
    // eslint-disable-next-line
  }, [location.state]);

  const onChangeBines = (bine) => {
    setPromocion({ ...promocion, bines: bine });
  };

  useEffect(() => {
    const cargarComercios = async () => {
      showLoader();
      try {
        const token = getToken();
        const response = await obtenerTodosLosComerciosRequest(token);
        setComercios(
          response
            .filter((x) => x.habilitado)
            .map((comercio) => ({
              codigo: comercio.idComercio,
              nombre: comercio.nombreComercio.toUpperCase(),
            }))
        );
      } catch (error) {
        console.error("Error al cargar comercios", error);
      } finally {
        hideLoader();
      }
    };
    cargarComercios();
    // eslint-disable-next-line
  }, []);

  const obtenerComercios = async (token) => {
    try {
      return await obtenerTodosLosComerciosRequest(token);
    } catch (error) {
      if (error.status === 500) {
        showNotification("error", "Error de conexión");
        return;
      }
      showNotification("error", "Ha ocurrido un error inesperado");
    } finally {
      hideLoader();
    }
  };

  const handleSetComercios = async () => {
    try {
      const token = getToken();
      showLoader();
      const response = await obtenerComercios(token);

      const comerciosHabilitados = response.filter(
        (comercio) => comercio.habilitado
      );
      const comerciosConContent = comerciosHabilitados.map((comercio) => ({
        ...comercio,
        content: comercio.nombreComercio,
      }));
      const nuevoArray = comerciosConContent.map(
        ({ nombreComercio, idComercio }) => ({
          codigo: idComercio,
          nombre: nombreComercio.toUpperCase(),
        })
      );
      nuevoArray.sort((a, b) => a.nombre.localeCompare(b.nombre));
      setComercios(nuevoArray);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleSelectComercio = (comercio) => {
    setSelectedComercio(comercio);
    setPromocion((prev) => ({ ...prev, idComercio: comercio.codigo }));
  };

  const validarDatos = () => {
    if (!promocion.idPromo.trim()) {
      setInputsError("Por favor, complete todos los campos.");
      return false;
    }
    return true;
  };

  const handleCrear = async (e) => {
    e.preventDefault();
    if (!validarDatos()) return;
    showLoader();

    setInputsError("");

    const promocionData = {
      ...promocion,
      idComercio: selectedComercio.codigo,
      porcentajeDescuento: Number(promocion.porcentajeDescuento),
      calendario: diasSeleccionados.map((d) => d.nombre).join(", "),
      topes: Number(promocion.topes),
      cantidad: Number(promocion.cantidad),
      habilitado: promocion.habilitado,
    };

    const token = getToken();
    const result = await sendRequestCreate(promocionData, token);

    if (result) {
      showNotification("success", "Creado correctamente!");
      navigate("/promociones");
    }
    hideLoader();
  };

  const handleEditar = async (e) => {
    e.preventDefault();
    if (!validarDatos()) return;
    setInputsError("");
    const token = getToken();
    const result = await sendRequestEdit(promocion, token);

    if (result) {
      showNotification("success", "¡Modificado correctamente!");
      navigate("/promociones");
    }
  };

  const sendRequestCreate = async (promocion, token) => {
    try {
      await crearPromocionRequest(promocion, token);
      return true;
    } catch (error) {
      handleError(error);
      return false;
    }
  };

  const sendRequestEdit = async (promocion, token) => {
    const { idPromocion } = promocion;

    try {
      await editarPromocionRequest(idPromocion, promocion, token);
      return true;
    } catch (error) {
      console.error("Error al editar la promoción:", error);
      handleError(error);
      return false;
    }
  };

  const isMobile = useMediaQuery("(max-width:768px)");
  const isSmallScreen = useMediaQuery("(max-width:1524px)");

  const handleError = (error) => {
    if (error.status === 409) {
      showNotification("error", "Ya existe una promoción con este ID");
    } else if (error.status === 500) {
      showNotification("error", "Error de conexión");
    } else {
      showNotification("error", "Ha ocurrido un error inesperado");
    }
  };

  const formatTope = (topes) => {
    return new Intl.NumberFormat("es-ES", {
      style: "currency",
      currency: "CLP",
      minimumFractionDigits: 0,
    }).format(topes);
  };

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        {isEdit ? "EDITAR" : "CREAR"} PROMOCIÓN
      </Typography>
      <br />
      <ShadowBox style={{ padding: "2rem" }}>
        <CustomBreadcrumbs
          items={[
            ...breadcrumbItems,
            isEdit
              ? { label: "EDITAR PROMOCIÓN" }
              : { label: "CREAR PROMOCIÓN" },
          ]}
        />
        <form>
          <Grid container spacing={4}>
            {selectedComercio || isEdit ? (
              <h2 style={{ width: "100%", padding: "2rem 2rem 0rem" }}>
                {JSON.parse(localStorage.getItem(SELECTED_COMMERCE_PROMO))
                  .nombre || selectedComercio}
              </h2>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <label style={{ color: "black" }}>SELECCIONE COMERCIO</label>
                <Combobox
                  rows={comercios}
                  placeholder="Seleccione comercio"
                  value={selectedComercio}
                  onSelect={handleSelectComercio}
                  style={{ width: "200px" }}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={3} md={3}>
              <label style={{ color: "black" }}>NOMBRE PROMOCIÓN</label>
              <Input
                style={{ width: "200px" }}
                type="text"
                placeholder="Nombre de la Promocion"
                value={promocion.idPromo}
                onChange={handleChange("idPromo")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label style={{ color: "black" }}>% DESCUENTO</label>
              <Input
                style={{ width: "200px" }}
                type="number"
                placeholder="Porcentaje de descuento"
                value={promocion.porcentajeDescuento}
                onChange={handleChange("porcentajeDescuento")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <label style={{ color: "black" }}>TOPE</label>
              <Input
                style={{ width: "200px" }}
                type="number"
                placeholder="Tope"
                value={promocion.topes}
                onChange={handleChange("topes")}
                inputProps={{ min: 0 }}
              />
              <Typography
                style={{
                  height: "0px",
                  overflowY: "initial",
                }}
                variant="body2"
                color="textSecondary"
              >
                {promocion.topes > 0 && formatTope(promocion.topes)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label style={{ color: "black" }}>VIGENCIA DESDE</label>
              <br />
              <SelectorFechaHora
                value={vigenciaDateDesde}
                onChange={handleChangeVigenciaDateDesde}
                InputProps={{ style: { borderColor: "black" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <label style={{ color: "black" }}>VIGENCIA HASTA</label>
              <br />
              <SelectorFechaHora
                value={vigenciaDateHasta}
                onChange={handleChangeVigenciaDateHasta}
                InputProps={{ style: { borderColor: "black" } }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <label style={{ color: "black" }}>CALENDARIO</label>
              <Combobox
                rows={Dias}
                placeholder="Selecciona un día..."
                onSelect={handleSelectDia}
                value={selectedDia}
                disableSelection={false}
                style={{ width: "200px" }}
                getOptionLabel={(option) => option.nombre}
              />
              {diasSeleccionados.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    height: isMobile ? "8rem" : isSmallScreen ? "auto" : "1px",
                    overflowY: "visible", // muestra sin mover grid
                  }}
                >
                  <ChipList
                    listado={diasSeleccionados}
                    label={(dia) => dia.nombre}
                    onDelete={handleDeleteDia}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: "5px" }}>
            <Grid item xs={12} sm={6} md={3}>
              <label style={{ color: "black" }}>CANTIDAD</label>
              <Input
                style={{ width: "200px" }}
                type="number"
                placeholder="Cantidad"
                value={promocion.cantidad}
                onChange={handleChange("cantidad")}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              style={{ marginTop: "10px" }}
            >
              <CSVToObject onChange={onChangeBines} />
            </Grid>
          </Grid>

          <br />
          {inputsError && (
            <div style={{ color: "red", marginTop: "10px" }}>{inputsError}</div>
          )}
          <br />

          <Button
            style={{ marginRight: "10px" }}
            variant="gray"
            onClick={handleVolver}
          >
            CANCELAR
          </Button>
          {isEdit ? (
            <Button onClick={handleEditar}>GUARDAR</Button>
          ) : (
            <Button onClick={handleCrear}>ACEPTAR</Button>
          )}
        </form>
      </ShadowBox>
    </>
  );
}
