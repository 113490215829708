import * as React from 'react';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import styles from "./selectorStyles";
import { enGB } from 'date-fns/locale';

export default function SelectorFechaHora({ InputProps, onChange, value }) {
  const minDate = new Date(); 

  const handleDateChange = (newDate) => {
    if (newDate) {
      onChange(newDate);
    } else {
      onChange("");  
    }
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DateTimePicker
        value={value}
        onChange={handleDateChange} 
        sx={styles}  
        minDate={minDate}
        inputFormat="yyyy-MM-dd'T'HH:mm:ss" 
        {...InputProps} 
      />
    </LocalizationProvider>
  );
}
