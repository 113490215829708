import React, { useEffect, useState } from "react";
import {
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Box,
  Paper,
} from "@mui/material";
import ShadowBox from "../../components/ShadowBox";
import RoundButton from "../../components/RoundButton";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isProduction } from "../../utils/utilidades";
import { isComercioUserRole, isOperacionesUserRole } from "../../connection/loginController";
import {
  eliminarApp,
  obtenerListadoApps,
} from "../../connection/mantenedores/aplicacionesMantenedor";
import { getToken } from "../../connection/tokenController";
import Dialogo from "../../components/Dialogo";
import { useLoader } from "../../context/LoaderContext";

const AplicacionesMantenedor = () => {
  let navigate = useNavigate();
  const { showLoader, hideLoader, showNotification } = useLoader();
  const [listaAplicaciones, setListaAplicaciones] = useState([]);
  const [dialogo, setDialogo] = useState({
    open: false,
    idApps: null,
    nombre: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const aplicacionesData = await obtenerListadoApps(getToken());
        setListaAplicaciones(aplicacionesData);
      } catch (error) {
        console.error("Error al cargar las aplicaciones:", error);
      } finally {
        hideLoader();
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleEdit = (aplicacion) => {
    navigate(`/aplicaciones/editar-aplicaciones/${aplicacion.idApps}`);
  };

  const handleDeleteClick = (aplicacion) => {
    setDialogo({
      open: true,
      idApps: aplicacion.idApps,
      nombre: aplicacion.nombre,
    });
  };

  const handleCancelDialog = () => {
    setDialogo({ ...dialogo, open: false });
  };

  const handleConfirmDelete = async () => {
    try {
      showLoader();
      await eliminarApp(dialogo.idApps, getToken());
      setListaAplicaciones((prevLista) =>
        prevLista.filter((app) => app.idApps !== dialogo.idApps)
      );
      showNotification("success", "Aplicación eliminada correctamente");
    } catch (error) {
      showNotification("error", "Esta aplicación está siendo utilizada.");
      console.error(
        "Esta aplicación está siendo utilizada y no puede ser eliminada.",
        error
      );
    } finally {
      hideLoader();
      setDialogo({ ...dialogo, open: false });
    }
  };

  return (
    <>
      <Typography variant="h5" sx={{ margin: "1rem 0" }}>
        MANTENEDOR DE APLICACIONES
      </Typography>
      <br />
      <ShadowBox sx={{ padding: "2rem" }}>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          {isProduction() || isComercioUserRole() || isOperacionesUserRole() ? (
            <> </>
          ) : (
            <Link
              to="/aplicaciones/crear-aplicacion"
              style={{
                color: "black",
                textDecoration: "none",
                marginRight: "1rem",
              }}
            >
              <RoundButton variant="outlined">AGREGAR</RoundButton>
            </Link>
          )}
        </div>
        <br />
        <Divider />
        <br />
        <br />
        {listaAplicaciones.length === 0 ? (
          <Typography sx={{ textAlign: "center", marginTop: "2rem" }}>
            No se encontraron aplicaciones
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              alignItems: "stretch",
            }}
          >
            {listaAplicaciones.map((aplicacion) => (
              <Paper
                key={aplicacion.idApps}
                sx={{
                  width: "200px",
                  height: "70px",
                  padding: "1rem",
                  display: "flex",
                  borderRadius: "8px",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  position: "relative",
                  boxShadow: '0 3px 8px #00000044',
                  ":hover": {
                    boxShadow: '0 3px 8px #00000050'
                  }
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "0.5rem",
                    right: "0.5rem",
                    display: "flex",
                    gap: "0.25rem",
                  }}
                >
                  <Tooltip title="Editar">
                    <IconButton
                      aria-label="editar"
                      onClick={() => handleEdit(aplicacion)}
                      size="small"
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton
                      aria-label="eliminar"
                      onClick={() => handleDeleteClick(aplicacion)}
                      size="small"
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    marginTop: "2rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {aplicacion.nombre}
                </Typography>
              </Paper>
            ))}
          </Box>
        )}
      </ShadowBox>
      <Dialogo
        open={dialogo.open}
        onClose={handleCancelDialog}
        title="ELIMINAR APP"
        text={`¿Estás seguro de que deseas eliminar la aplicación ${dialogo.nombre}?`}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default AplicacionesMantenedor;