import { removeToken } from "./tokenController";

// LOCALSTORAGE KEYS
const USER_ROLE_KEY = "userRole";
const USER_EMAIL_KEY = "userEmail";
const USER_FIRSTPASS_KEY = "firstPass";
const ID_COMERCIO_KEY = "idComercio";

// ROLE VALUES
export const DESARROLLO_ROLE_VALUE = "Desarrollo";
export const ADMIN_ROLE_VALUE = "admin";
export const COMERCIO_ROLE_VALUE = "Comercios";
export const OPERACIONES_ROLE_VALUE = "Operaciones";

/*
* Cuando el usuario inicia sesión con una clave temporal
* (ya sea por que es primera vez o por olvidar su clave)
*/
export function saveUserCambioClave(username, password) {
    localStorage.setItem(USER_EMAIL_KEY, username);
    localStorage.setItem(USER_FIRSTPASS_KEY, password);
}

/*
* Usuario administración
*/
export function saveAdminUser() {
    localStorage.setItem(USER_ROLE_KEY, ADMIN_ROLE_VALUE);
}

/*
* Usuario equipo de desarrollo
*/
export function saveUserDesarrollo() {
    localStorage.setItem(USER_ROLE_KEY, DESARROLLO_ROLE_VALUE);
}

/*
* Usuario Getnet
*/
export function saveUserOperaciones() {
    localStorage.setItem(USER_ROLE_KEY, OPERACIONES_ROLE_VALUE);
}
/*
* Usuario Comercio
*/
export function saveUserComercioRole() {
    localStorage.setItem(USER_ROLE_KEY, COMERCIO_ROLE_VALUE);
}
/*
* Guardar rol del usuario que ha iniciado sesión
*/
export function saveUserRole(respuesta) {
    if(respuesta.rol === "Desarrollo"){
        localStorage.setItem(USER_ROLE_KEY, DESARROLLO_ROLE_VALUE)
    }
    if(respuesta.rol === 'Operaciones'){
        localStorage.setItem(USER_ROLE_KEY, OPERACIONES_ROLE_VALUE)
    }
    if(respuesta.rol === "Comercios"){
    localStorage.setItem(USER_ROLE_KEY, COMERCIO_ROLE_VALUE);
    }
}
/*
* En inicio de sesión como comercio, se guarda el id de comercio
*/
export function saveIdComercio(idComercio){
    localStorage.setItem(ID_COMERCIO_KEY, idComercio);
}
/*
* En inicio de sesión como comercio, obtiene el id de comercio
*/
export function getIdComercio(){
    return localStorage.getItem(ID_COMERCIO_KEY)
}
/*
* Consulta si se inició como comercio
*/
export function isComercioUserRole() {
    return localStorage.getItem(USER_ROLE_KEY) === COMERCIO_ROLE_VALUE;
}
export function isOperacionesUserRole() {
    return true
    // return localStorage.getItem(USER_ROLE_KEY) === OPERACIONES_ROLE_VALUE;
}

/*
* Obtiene el rol del usuario
*/
export function getRolUsuario() {
    return localStorage.getItem(USER_ROLE_KEY);
}

/*
* Elimina los datos del localstorage
*/
export function logoutHandler() {
    localStorage.removeItem(USER_ROLE_KEY);
    localStorage.removeItem(USER_EMAIL_KEY);
    localStorage.removeItem(USER_FIRSTPASS_KEY);
    localStorage.removeItem(ID_COMERCIO_KEY);
    removeToken();
}
/*
* Consulta si la url desde la cual se está intentando entrar
* es de comercios o del sistema operaciones
*/
export function isLoginComercio() {
    const urlComerio = process.env.REACT_APP_URL_COMERCIO;
    if (window.location.origin === urlComerio)
        return true;
    return false;
}