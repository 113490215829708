import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import LinkOffIcon from "@mui/icons-material/LinkOff";

const MulticomercioExpandedTableRow = ({ idCommercioPadre, onDelete }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleConfirmDelete = () => {
    if (idCommercioPadre.idCommerceDataMc) {
      onDelete(idCommercioPadre.idCommerceDataMc);
    }
    setOpenDialog(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>{idCommercioPadre.nombreComercio}</TableCell>
        <TableCell>{idCommercioPadre.rut}</TableCell>
        <TableCell>{idCommercioPadre.numeroComercioGetnet}</TableCell>
        <TableCell>
          <Tooltip title="Desvincular Comercio" placement="right">
            <IconButton onClick={handleOpenDialog}>
              <LinkOffIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="confirm-delete-dialog"
      >
        <DialogTitle id="confirm-delete-dialog">
          Confirmar eliminación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas desvincular el comercio{" "}
            <b>{idCommercioPadre.nombreComercio}</b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleConfirmDelete}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MulticomercioExpandedTableRow;
