import { getApiUrl, RequestContentType, getRequest, postRequest, putRequest, deleteRequest } from "../apiConnection";

const API_URL = getApiUrl();

export const crearPromocionRequest = async (objeto, token) => {
    return await postRequest(API_URL, "/api/promociones", RequestContentType.APPLICATION_JSON, objeto, token);
}
export const listarPromocionesRequest = async (idComercio, token) => {
    const url = `/api/promociones?idComercio=${idComercio}`;
    return await getRequest(API_URL, url, token);
}
export const listarPromocionPorIdComercioRequest = async (idComercio, token) => {
    return await getRequest(API_URL, `/api/promociones?idcomercio=${idComercio}`, token)
}
export const editarPromocionRequest = async (idPromocion, objeto, token) => {
    const url = `/api/promociones/${idPromocion}`;
    return await putRequest(API_URL, url, RequestContentType.APPLICATION_JSON, objeto, token);
}
export const eliminarPromocionRequest = async (idPromocion, token) => {
    const url = `/api/promociones/${idPromocion}`;
    return await deleteRequest(API_URL, url, token);
}
