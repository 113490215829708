import React, { useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import styles from "./selectorStyles"

export default function SelectorFecha(props) {
  const datepicker = useRef(null);

  const dateFormat = props.Promo ? "dd-MM-yyyy HH:mm" : "dd-MM-yyyy";
  const hoy = new Date();
  hoy.setHours(0, 0, 0, 0);
  const minDateFormat = props.Promo ? hoy : props.min
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        ref={datepicker}
        format={dateFormat} 
        value={props.value}
        onChange={(date) => props.onChange(date)}
        sx={styles}
        minDate={minDateFormat || null}
        maxDate={props.max || null}
      />
    </LocalizationProvider>
  );
}
