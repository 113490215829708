import React, { useEffect } from 'react'
import Transacciones from '../../components/Transacciones'
import { obtenerTxsC2CRequest } from '../../connection/mantenedores/transacciones';
import { getToken } from '../../connection/tokenController';
import { useLoader } from '../../context/LoaderContext';
import { isLoginComercio } from '../../connection/loginController';
import { APP_C2C, obtenerAppsHabilitadas } from '../../connection/AppsController';
import { useNavigate } from 'react-router-dom';

export default function C2CTransacciones() {
    const { showLoader, hideLoader, showNotification } = useLoader();
    const navigate = useNavigate()
    
    const handleBuscar = async (idPos, fechaDesde, fechaHasta, comandos) => {
        return new Promise(async (resolve, reject) => {
            try {
                showLoader();
                const respuesta = await obtenerTxsC2CRequest(
                    idPos,
                    fechaDesde,
                    fechaHasta,
                    comandos,
                    getToken()
                );
                const listado = respuesta.map((item) => {
                    return {
                        idTxs: item.idPosTxs,
                        comando: item.comando,
                        fechaRequest: new Date(item.fechaRequest),
                        functionCode: item.functionCode,
                        responseCode: item.responseCode,
                        responseMessage: item.responseMessage,
                        fechaResponse:
                            new Date(item.fechaResponse),
                        request: item.request,
                        response: item.response,
                    };
                });
                resolve(listado);
            } catch (error) {
                resolve([]);
                if (error.status === 500) {
                    showNotification("error", "Error de conexión");
                    return;
                }
                showNotification("error", "Ha ocurrido un error inesperado");
            }finally {
                hideLoader();
            }
        })
    }
    useEffect(() =>{
        checkearValidRoute()
        // eslint-disable-next-line
    },[])

    
    const checkearValidRoute = async () =>  {
        if (!isLoginComercio()) return;
        const nombresApps = await obtenerAppsHabilitadas();
        if (!nombresApps.includes(APP_C2C)) {
          navigate('/404');
        }
      }
    return (
        <Transacciones titulo="G-SERVICES" onBuscar={handleBuscar} variant="c2c" />
    )
}
